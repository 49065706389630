<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <!-- Field: Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="اسم"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="userData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="البريد الإلكتروني"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="الحالة"
            label-for="user-status"
          >
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="دور المستخدم"
            label-for="user-role"
          >
            <v-select
              v-model="userData.role_id"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
              @input="roleChanged"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="رقم الهاتف"
            label-for="mobile"
          >
            <b-form-input
              id="mobile"
              v-model="userData.mobile"
            />
          </b-form-group>
        </b-col>
        <!-- extra fields -->
        <b-col
          v-if="isEvaluator(userData.role_id)"
          cols="12"
          md="4"
        >
          <b-form-group
            label="رخصة التقييم"
            label-for="license_number"
          >
            <b-form-input
              id="license_number"
              v-model="userData.license_number"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">الأذونات</span>
        </b-card-title>
      </b-card-header>

      <b-table
        striped
        responsive
        class="mb-0"
        :items="items"
        :fields="fields"
      >
        <template #cell(module)="data">
          {{ $t(`${data.value}_permission`) }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            :checked="userData.abilities[data.item.module][keys[data.field.key]]"
            @change="setPermission($event, data)"
          />
        </template>
      </b-table>
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateUser"
    >
      حفظ التغييرات
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="resetData()"
    >
      إعادة ضبط
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAlert,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from 'bootstrap-vue';
import { alertMessageMixin } from '@core/mixins/ui/feedback';

import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import { required, alphaNum, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useUsersList from '../users-list/useUsersList';

export default {
  components: {
    BButton,
    BAlert,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [alertMessageMixin],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    resetData() {
      this.$emit('reset-user-data');
    },
    roleChanged(id) {
      this.userData.role_id = id;
    },
    getRole(id) {
      return this.roleOptions.find((role) => role.value === id);
    },
    isEvaluator(id) {
      const role = this.getRole(id);
      return role && role.name.includes('evaluator');
    },
    setPermission(event, data) {
      this.userData.abilities[data.item.module][data.field.key] = event;
    },
    updateUser() {
      // Assuming userData is an object with the role property
      this.userData.role = this.userData.role_id;
      this.userData.permissions = Object.keys(this.userData.abilities)
        .map((key) => this.permissionOptions
          .filter((value) => this.userData.abilities[key][value] === true)
          .map((value) => `${key}_${value}`))
        .flat();
      this.$store
        .dispatch('app-user/updateUser', { userData: this.userData, id: this.userData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/user').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();
    const permissionOptions = ref([]);
    const moduleOptions = ref([]);
    const roleOptions = ref([]);
    const items = ref(Object.values(props.userData.abilities));

    store.dispatch('app-user/fetchRoles')
      .then((response) => {
        let { roles } = response.data;
        roles = roles
        .filter((role) => role.name !== "company_admin")
        .map((role) => ({
          value: role.id,
          label: role.name_arabic,
          ...role,
        }));

        roleOptions.value = roles;
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          permissionOptions.value = undefined;
        }
      });

    const statusOptions = [
      { label: 'نشط', value: 'Active' },
      { label: 'غير نشط', value: 'Inactive' },
    ];

    const fields = [
      {
        key: 'module',
        label: 'الوحدة',
      },
      {
        key: 'read',
        label: 'قراءة',
      },
      {
        key: 'update',
        label: 'كتابة',
      },
      {
        key: 'create',
        label: 'إنشاء',
      },
      {
        key: 'delete',
        label: 'حذف',
      },
    ];

    const keys = {
      read: 0,
      update: 1,
      create: 2,
      delete: 3,
    };
    const permissionsData = [
      {
        module: 'المستخدمين',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'العملاء',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'إدارة العقود',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'إدارة المالية',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
    ];

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
    //   // eslint-disable-next-line no-param-reassign
    //   props.userData.avatar = base64
    // })
    const oldUserData = JSON.parse(JSON.stringify(props.userData));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(oldUserData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetuserData);

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      fields,
      keys,
      permissionsData,
      permissionOptions,
      moduleOptions,
      items,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
